<template>
  <div class="mx-2">
    <b-table-simple responsive caption-top :table-variant="variant" bordered>
      <caption class="text-center">
        <h4 style="margin-top:48px;">{{ `${item.destinationCountryName[lang]} - ${item.destinationName[lang]}  ${item.hotelName[lang]} - ${item.nights} ${$t('home.nights')}` }}</h4>
      </caption>
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>{{ $t('price-plan.dates') }}</b-th>
          <b-th>{{ $t('price-plan.nights') }}</b-th>
          <b-th>{{ $t('price-plan.single') }}</b-th>
          <b-th>{{ $t('price-plan.double_room') }}</b-th>
          <b-th>{{ $t('price-plan.2adults+1child') }}</b-th>
          <b-th>{{ $t('price-plan.3adults') }}</b-th>
          <b-th>{{ $t('price-plan.2adults+2children') }}</b-th>
          <b-th>{{ $t('price-plan.2adults+3children') }}</b-th>
          <b-th>{{ $t('price-plan.link') }}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody >
        <b-tr style="text-align: center !important;">
          <b-td><div>{{ dates }}</div></b-td>
          <b-td><div>{{ item.nights }}</div></b-td>
          <b-td v-if="adult1.active">
            <div>{{`$ ${adult1.price}`}}</div>
            <div>{{ adult1.classCode }}, {{ adult1.basis }}</div>
            <div v-if="type==='changes' && adult1.changeType === 2 && adult1.changedPriceValue">
              {{ adult1.changedPriceValue > 0 ? `+${adult1.changedPriceValue}` : adult1.changedPriceValue }}
            </div>
            <div v-if="isFcAgentMarketerMode"><s>${{ adult1.grossRate }}</s></div>
          </b-td>
          <b-td v-else></b-td>

          <b-td v-if="adult2.active">
            <div>{{`2 * $ ${adult2.price}`}}</div>
            <div>{{ adult2.classCode}}, {{ adult2.basis }}</div>
            <div v-if="type==='changes' && adult2.changeType === 2 && adult2.changedPriceValue">
              {{ adult2.changedPriceValue > 0 ? `+${adult2.changedPriceValue}` : adult2.changedPriceValue }}
            </div>
            <div v-if="isFcAgentMarketerMode"><s>${{ adult2.grossRate }}</s></div>
          </b-td>
          <b-td v-else></b-td>

          <b-td v-if="adult21.active">
            <div>{{`3 * $ ${adult21.price}`}}</div>
            <div>{{ adult21.classCode}}, {{ adult21.basis }}</div>
            <div v-if="type==='changes' && adult21.changeType === 2 && adult21.changedPriceValue">
              {{ adult21.changedPriceValue > 0 ? `+${adult21.changedPriceValue}` : adult21.changedPriceValue }}
            </div>
            <div v-if="isFcAgentMarketerMode"><s>${{ adult21.grossRate }}</s></div>
          </b-td>
          <b-td v-else></b-td>

          <b-td v-if="adult3.active">
            <div>{{`3 * $ ${adult3.price}`}}</div>
            <div>{{ adult3.classCode}}, {{ adult3.basis }}</div>
            <div v-if="type==='changes' && adult3.changeType === 2 && adult3.changedPriceValue">
              {{ adult3.changedPriceValue > 0 ? `+${adult3.changedPriceValue}` : adult3.changedPriceValue }}
            </div>
            <div v-if="isFcAgentMarketerMode"><s>${{ adult3.grossRate }}</s></div>
          </b-td>
          <b-td v-else></b-td>

          <b-td v-if="adult22.active">
            <div>{{`4 * $ ${adult22.price}`}}</div>
            <div>{{ adult22.classCode}}, {{ adult22.basis }}</div>
            <div v-if="type==='changes' && adult22.changeType === 2 && adult22.changedPriceValue">
              {{ adult22.changedPriceValue > 0 ? `+${adult22.changedPriceValue}` : adult22.changedPriceValue }}
            </div>
            <div v-if="isFcAgentMarketerMode"><s>${{ adult22.grossRate }}</s></div>
          </b-td>
          <b-td v-else></b-td>

          <b-td v-if="adult23.active">
            <div>{{`5 * $ ${adult23.price}`}}</div>
            <div>{{ adult23.classCode}}, {{ adult23.basis }}</div>
            <div v-if="type==='changes' && adult23.changeType === 2 && adult23.changedPriceValue">
              {{ adult23.changedPriceValue > 0 ? `+${adult23.changedPriceValue}` : adult23.changedPriceValue }}
            </div>
            <div v-if="isFcAgentMarketerMode"><s>${{ adult23.grossRate }}</s></div>
          </b-td>
          <b-td v-else></b-td>

          <b-td><a :href="`${baseDomain}${item.selectedPackageUrl}${fcAgentModeParam}`">{{$t('price-plan.opening')}}</a></b-td>
        </b-tr>
        <b-tr v-if="allTripFlights && allTripFlights.length">
          <b-td colspan="9" style="text-align: center !important;"> {{ $t('price-plan.possible-flights') }} </b-td>
        </b-tr>
        <b-tr v-for="(flight, inx) in allTripFlights" :key="inx">
          <b-td colspan="5" style="border: 2px solid #aaa !important;text-align: center;">
            <p style="margin: 0;">{{ $t('price-plan.departure-flight') }}</p>
            <p style="margin: 0;">{{ flightArrInfo(flight) }}</p>
            <p style="margin: 0;">{{ flightArrRemark(flight) }}</p>
          </b-td>
          <b-td colspan="5" style="border: 2px solid #aaa !important;text-align: center;">
            <p style="margin: 0;">{{ $t('price-plan.return-flight') }}</p>
            <p style="margin: 0;">{{ flightReturnInfo(flight) }}</p>
            <p style="margin: 0;">{{ flightReturnRemark(flight) }}</p>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BTableSimple, BThead, BTr, BTh, BTbody, BTd } from 'bootstrap-vue';

const { VUE_APP_MAIN_DOMAIN } = process.env;

export default {
  name: 'pricePlanItem',
  components: {
    BTableSimple, BThead, BTr, BTh, BTbody, BTd,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      isFcAgentMarketerMode: 'GET_FC_AGENT_MARKETER_MODE',
      searchData: 'GET_SEARCH_INFORMATION',
    }),
    fcAgentModeParam() {
      return (this.isFcAgentMarketerMode) ? '&fc-agent-mode' : '';
    },
    adult23() {
      return this.getSpecificItem(2, 3);
    },
    adult22() {
      return this.getSpecificItem(2, 2);
    },
    adult3() {
      return this.getSpecificItem(3, 0);
    },
    adult21() {
      return this.getSpecificItem(2, 1);
    },
    adult2() {
      return this.getSpecificItem(2, 0, 'DB');
    },
    adult1() {
      return this.getSpecificItem(1, 0, 'SG');
    },
    dates() {
      const { item } = this;
      const { arrivalDate, departureDate } = item;
      return `${departureDate.split('-').reverse().splice(0, 2).join('.')} - ${arrivalDate.split('-').reverse().splice(0, 2).join('.')}`;
    },
    allTripFlights() {
      const { item } = this;
      const { allFlights } = item;

      return allFlights;
    },
    variant() {
      return this.searchData?.dealIds?.includes(this.item.id) ? 'success' : 'light';
    },
  },
  data() {
    return {
      baseDomain: VUE_APP_MAIN_DOMAIN,
    };
  },
  methods: {
    getSpecificItem(cAdult, cChild, roomTypeCode) {
      const { item } = this;
      const { roomRates } = item;
      const { pricePlanChangeInfo } = item;
      const oldRoomRates = pricePlanChangeInfo?.oldRoomRates || null;
      let oneItem = null;
      if (roomTypeCode) {
        oneItem = roomRates.filter((rate) => rate.roomTypeCode === roomTypeCode)?.sort((a, b) => a.avgPrice - b.avgPrice)[0];
      } else {
        oneItem = roomRates.filter((rate) => rate.adults === cAdult && rate.children === cChild)?.sort((a, b) => a.avgPrice - b.avgPrice)[0];
      }
      const oldItem = oldRoomRates ? oldRoomRates.filter((rate) => rate.adults === cAdult && rate.children === cChild)?.sort((a, b) => a.avgPrice - b.avgPrice)[0] : null;
      const changedPriceValue = oldItem ? oldItem.avgPrice - oneItem.avgPrice : 0;
      const changeType = pricePlanChangeInfo?.changeType || null;

      return {
        active: !!oneItem,
        price: oneItem?.avgPrice,
        classCode: oneItem?.roomClassCode,
        basis: oneItem?.boardBasisCode,
        changedPriceValue,
        grossRate: oneItem?.avgPriceBd || '',
        changeType,
      };
    },
    flightArrInfo(flight) {
      const FlightDetail = flight?.FlightDetail || [];
      const flightArr = FlightDetail[0];
      if (!flightArr) return '';

      const dates = flightArr?.FL_Date.split('-').reverse().join('/') || '';

      return `${flightArr.FL_From_Route} - ${flightArr.FL_To_Route} ${dates} ${flightArr.FL_AIRLINE}${flightArr.FL_Flt_Number} ${flightArr.FL_Dep_Hour} - ${flightArr.FL_Arrv_Hour}`;
    },
    flightArrRemark(flight) {
      const FlightDetail = flight?.FlightDetail || [];
      const flightArr = FlightDetail[0];
      if (!flightArr) return '';

      return flightArr?.Authorization_msg || '';
    },
    flightReturnInfo(flight) {
      const FlightDetail = flight?.FlightDetail || [];
      const flightReturn = FlightDetail[1];

      if (!flightReturn) return '';

      const dates = flightReturn?.FL_Date.split('-').reverse().join('/') || '';

      return `${flightReturn.FL_From_Route} - ${flightReturn.FL_To_Route} ${dates} ${flightReturn.FL_AIRLINE}${flightReturn.FL_Flt_Number} ${flightReturn.FL_Dep_Hour} - ${flightReturn.FL_Arrv_Hour}`;
    },
    flightReturnRemark(flight) {
      const FlightDetail = flight?.FlightDetail || [];
      const flightReturn = FlightDetail[1];
      if (!flightReturn) return '';

      return flightReturn?.Authorization_msg || '';
    },
  },
};
</script>

<style scoped>
  table td div, table td p {
    line-height: 1rem;
  }
</style>
